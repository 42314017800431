export const comments = {
  ja: {
    score: {
      up: `生活習慣スコアは10月より%d点上がりました。寒さに負けず、良い生活習慣を身につけられていますね。<br>
      今年も残すところわずかとなりました。年末年始は、クリスマスや忘年会など体重が増えやすい時期です。一時的に増えた体重は、<b>3日以内</b>に元に戻すことを心がけましょう。いち早く身体の変化に気が付くためにも、こまめな体重計測をお忘れなく♪`,
      down: `生活習慣スコアは10月より%d点下がりました。仕事が忙しくなったなど、心当たりはありますか？<br>
      今年も残すところわずかとなりました。年末年始は、クリスマスや忘年会など体重が増えやすい時期です。一時的に増えた体重は、<b>3日以内</b>に元に戻すことを心がけましょう。いち早く身体の変化に気が付くためにも、こまめな体重計測をお忘れなく♪  `,
      equal100: `生活習慣スコアは10月に引き続き%満点%です。寒さに負けず、大変素晴らしいです！<br>
      今年も残すところわずかとなりました。年末年始は、クリスマスや忘年会など体重が増えやすい時期です。一時的に増えた体重は、<b>3日以内</b>に元に戻すことを心がけましょう。いち早く身体の変化に気が付くためにも、こまめな体重計測をお忘れなく♪`,
      equal: `生活習慣スコアは10月と同じ点数です。<br>
      今年も残すところわずかとなりました。年末年始は、クリスマスや忘年会など体重が増えやすい時期です。一時的に増えた体重は、<b>3日以内</b>に元に戻すことを心がけましょう。いち早く身体の変化に気が付くためにも、こまめな体重計測をお忘れなく♪`,
    },
    steps: {
      up: `歩数スコアは10月より%d点上がりました。<br>
      その調子です！今より少しでも長く、少しでも元気に体を動かすことが健康への第一歩になりますよ。`,
      down: `歩数スコアは10月より%d点下がりました。<br>
      同じ歩数でも速く歩いた方が消費カロリーは多くなります。忙しくてなかなか歩けない人は、歩く速さを意識してみてください。`,
      equal: `歩数スコアは10月と同じ点数です。<br>
      今より少しでも長く、少しでも元気に体を動かすことが健康への第一歩になりますよ。`,
    },
    exercise: {
      up: `運動のスコアは10月より%d点上がりました。<br>
      家事や大掃除をしながら、身体をうごかしてみませんか？<a href="https://go.andwell.jp/library/63849cb1-103c-11ec-a0b5-06d158a16ffc">＜こちら＞</a>の動画で「ながらエクササイズ」をご紹介。`,
      down: `運動のスコアは10月より%d点下がりました。<br>
      家事や大掃除をしながら、身体をうごかしてみませんか？<a href="https://go.andwell.jp/library/63849cb1-103c-11ec-a0b5-06d158a16ffc">＜こちら＞</a>の動画で「ながらエクササイズ」をご紹介。`,
      equal: `運動のスコアは10月と同じ点数です。<br>
      家事や大掃除をしながら、身体をうごかしてみませんか？<a href="https://go.andwell.jp/library/63849cb1-103c-11ec-a0b5-06d158a16ffc">＜こちら＞</a>の動画で「ながらエクササイズ」をご紹介。`,
    },
    meal: {
      up: `食事のスコアは10月より%d点上がりました。<br>
      飲み会や会食の予定がある日は、その前後で調整しましょう。全く食べないのではなく、量を少なくしたり、野菜を多く食べるように心がけて。`,
      down: `食事のスコアは10月より%d点下がりました。<br>
      飲み会や会食の予定がある日は、その前後で調整しましょう。全く食べないのではなく、量を少なくしたり、野菜を多く食べるように心がけて。`,
      equal: `食事のスコアは10月と同じ点数です。<br>
      飲み会や会食の予定がある日は、その前後で調整しましょう。全く食べないのではなく、量を少なくしたり、野菜を多く食べるように心がけて。`,
    },
    drinking: {
      up: `飲酒のスコアは10月より%d点上がりました。<br>
      お酒を飲む機会が多くなるこの季節。おつまみを気にかけて、肝臓の負担を軽減させませんか？<a href="https://go.andwell.jp/library/9b76d2e5-34a4-11ed-8934-0af363eb5062">＜こちら＞</a>のコラムをCheck！`,
      down: `飲酒のスコアは10月より%d点下がりました。<br>
      お酒を飲む機会が多くなるこの季節。おつまみを気にかけて、肝臓の負担を軽減させませんか？<a href="https://go.andwell.jp/library/9b76d2e5-34a4-11ed-8934-0af363eb5062">＜こちら＞</a>のコラムをCheck！`,
      equal: `飲酒のスコアは10月と同じ点数です。<br>
      お酒を飲む機会が多くなるこの季節。おつまみを気にかけて、肝臓の負担を軽減させませんか？<a href="https://go.andwell.jp/library/9b76d2e5-34a4-11ed-8934-0af363eb5062">＜こちら＞</a>のコラムをCheck！`,
    },
    sleep: {
      up: `睡眠のスコアは10月より%d点上がりました。<br>
      朝日を浴びることで生活リズムを整えられます。起きたらカーテンを開けて、太陽の光を部屋に取り込みましょう。`,
      down: `睡眠のスコアは10月より%d点下がりました。<br>
      朝日を浴びることで生活リズムを整えられます。起きたらカーテンを開けて、太陽の光を部屋に取り込みましょう。`,
      equal: `睡眠のスコアは10月と同じ点数です。<br>
      朝日を浴びることで生活リズムを整えられます。起きたらカーテンを開けて、太陽の光を部屋に取り込みましょう。`,
    },
    stress: {
      up: `ストレスのスコアは10月より%d点上がりました。<br>
      「深呼吸をする」「人と話す」「ゆっくりお風呂に浸かる」など、どれもストレス解消に繋がります。忙しい時こそ、こまめな息抜きが大切♪`,
      down: `ストレスのスコアは10月より%d点下がりました。<br>
      「深呼吸をする」「人と話す」「ゆっくりお風呂に浸かる」など、どれもストレス解消に繋がります。忙しい時こそ、こまめな息抜きが大切♪`,
      equal: `ストレスのスコアは10月と同じ点数です。<br>
      「深呼吸をする」「人と話す」「ゆっくりお風呂に浸かる」など、どれもストレス解消に繋がります。忙しい時こそ、こまめな息抜きが大切♪`,
    },
  },
  en: {
    score: {
      up: `Your lifestyle score has gone up by %d pts since October. You're maintaining good lifestyle habits, without letting the cold get the better of you.<br>
      There's only a little bit of this year left. What with Christmas, year-end parties, and all the other seasonal festivities, it's easy to put on weight around New Year's. If your weight goes up temporarily, try to get it back down to normal again <b>within 3 days</b>. Don't forget to weigh yourself regularly, so that you'll notice any changes in your body quickly.♪`,
      down: `Your lifestyle score has gone down by %d pts since October. Do you have any idea why this could be? (For example, have you been especially busy at work lately?)<br>
      There's only a little bit of this year left. What with Christmas, year-end parties, and all the other seasonal festivities, it's easy to put on weight around New Year's. If your weight goes up temporarily, try to get it back down to normal again <b>within 3 days</b>. Don't forget to weigh yourself regularly, so that you'll notice any changes in your body quickly.♪`,
      equal100: `Your lifestyle score is the %maximum% again, the same as October. You're doing great despite the cold!<br>
      There's only a little bit of this year left. What with Christmas, year-end parties, and all the other seasonal festivities, it's easy to put on weight around New Year's. If your weight goes up temporarily, try to get it back down to normal again <b>within 3 days</b>. Don't forget to weigh yourself regularly, so that you'll notice any changes in your body quickly.`,
      equal: `Your lifestyle score is the same as October.<br>
      There's only a little bit of this year left. What with Christmas, year-end parties, and all the other seasonal festivities, it's easy to put on weight around New Year's. If your weight goes up temporarily, try to get it back down to normal again <b>within 3 days</b>. Don't forget to weigh yourself regularly, so that you'll notice any changes in your body quickly.♪`,
    },
    steps: {
      up: `Your steps score has gone up %d pts since October.<br>
      Keep up the good work! The first step toward being healthier is to exercise a little longer and more energetically than you do now.`,
      down: `Your steps score has gone down %d pts since October.<br>
      Even if you walk the same steps, the faster you do it, the more calories you'll burn. If you're too busy to walk regularly, try to be conscious of your walking speed.`,
      equal: `Your steps score is the same as October.<br>
      The first step toward being healthier is to exercise a little longer and more energetically than you do now.`,
    },
    exercise: {
      up: `Your exercise score has gone up %d pts since October.<br>
      Why not try exercising while doing household chores and cleaning? <a href="https://go.andwell.jp/library/63849cb1-103c-11ec-a0b5-06d158a16ffc">[This]</a> video is about "exercising while doing other things."`,
      down: `Your exercise score has gone down %d pts since October.<br>
      Why not try exercising while doing household chores and cleaning? <a href="https://go.andwell.jp/library/63849cb1-103c-11ec-a0b5-06d158a16ffc">[This]</a> video is about "exercising while doing other things."`,
      equal: `Your exercise score is the same as October.<br>
      Why not try exercising while doing household chores and cleaning? <a href="https://go.andwell.jp/library/63849cb1-103c-11ec-a0b5-06d158a16ffc">[This]</a> video is about "exercising while doing other things."`,
    },
    meal: {
      up: `Your food score has gone up %d pts since October.<br>
      When you have drinking or dinner party plans, adjust your habits on the surrounding days to make allowances for them. Rather than not eating at all, try to eat less, and to eat more vegetables.`,
      down: `Your food score has gone down %d pts since October.<br>
      When you have drinking or dinner party plans, adjust your habits on the surrounding days to make allowances for them. Rather than not eating at all, try to eat less, and to eat more vegetables.`,
      equal: `Your food score is the same as October.<br>
      When you have drinking or dinner party plans, adjust your habits on the surrounding days to make allowances for them. Rather than not eating at all, try to eat less, and to eat more vegetables.`,
    },
    drinking: {
      up: `Your alcohol score has gone up %d pts since October.<br>
      This season is full of opportunities for drinking alcohol. How about being careful about snacks, and lightening the load on your liver? Check out <a href="https://go.andwell.jp/library/9b76d2e5-34a4-11ed-8934-0af363eb5062">[this]</a> column!`,
      down: `Your alcohol score has gone down %d pts since October.<br>
      This season is full of opportunities for drinking alcohol. How about being careful about snacks, and lightening the load on your liver? Check out <a href="https://go.andwell.jp/library/9b76d2e5-34a4-11ed-8934-0af363eb5062">[this]</a> column!`,
      equal: `Your alcohol score is the same as October.<br>
      This season is full of opportunities for drinking alcohol. How about being careful about snacks, and lightening the load on your liver? Check out <a href="https://go.andwell.jp/library/9b76d2e5-34a4-11ed-8934-0af363eb5062">[this]</a> column!`,
    },
    sleep: {
      up: `Your sleep score has gone up %d pts since October.<br>
      Getting some sunshine in the morning can help you keep your lifestyle rhythm in good shape. When you wake up, open the curtains and let the sunlight in.`,
      down: `Your sleep score has gone down %d pts since October.<br>
      Getting some sunshine in the morning can help you keep your lifestyle rhythm in good shape. When you wake up, open the curtains and let the sunlight in.`,
      equal: `Your sleep score is the same as October.<br>
      Getting some sunshine in the morning can help you keep your lifestyle rhythm in good shape. When you wake up, open the curtains and let the sunlight in.`,
    },
    stress: {
      up: `Your stress score has gone up %d pts since October.<br>
      Here are some things that can help relieve stress: breathing deeply, talking to people, and taking a long, relaxing bath. When you're busy, it's especially important to take frequent breaks.♪`,
      down: `Your stress score has gone down %d pts since October.<br>
      Here are some things that can help relieve stress: breathing deeply, talking to people, and taking a long, relaxing bath. When you're busy, it's especially important to take frequent breaks.♪`,
      equal: `Your stress score is the same as October.<br>
      Here are some things that can help relieve stress: breathing deeply, talking to people, and taking a long, relaxing bath. When you're busy, it's especially important to take frequent breaks.♪`,
    },
  },
}
